
[data-v-1821a0a6] .el-step__title {
  cursor: pointer;
}
.ticketMain[data-v-1821a0a6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ticketLeft[data-v-1821a0a6] {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}
.ticketLeft[data-v-1821a0a6] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketRight[data-v-1821a0a6] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketStep[data-v-1821a0a6] {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}
.ticketRight[data-v-1821a0a6] {
  width: calc(100% - 146px);
  height: 100%;
}
.ticketContent[data-v-1821a0a6] {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}
.ticketDisclose[data-v-1821a0a6] {
  background-color: #fff;
}
.ticket-head[data-v-1821a0a6] {
  position: relative;
}
.ticket-head-btn[data-v-1821a0a6] {
  position: absolute;
  top: 7px;
  left: 150px;
}
.ticket-head-code[data-v-1821a0a6] {
  position: absolute;
  top: 7px;
  left: 400px;
}
.ticketForm[data-v-1821a0a6] {
  padding: 12px;
  background-color: #FFFFFF;
}
.ticketGas[data-v-1821a0a6] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.signatureImg[data-v-1821a0a6] {
  width: 70px;
  height: 32px;
  /* display: block; */
  margin: auto;
}
[data-v-1821a0a6] .el-textarea .el-input__count {
  height: 20px !important;
}
